.section{
    padding: 70px 0;
  position: relative;
  background: #ffffff;
} 
.section-contact-us{
    .title{
        margin-bottom: 15px;
    }

    .description{
        margin-bottom: 30px;
    }

    .input-group,
    .send-button,
    .textarea-container{
        padding: 0 40px;
    }

    .textarea-container{
        margin: 40px 0;
    }

    a.btn{
        margin-top: 35px;
    }
} 
.text-center{
    text-align: center !important;
}
.title{
    font-weight: bold;
    padding-top: 30px;


    &.title-up{
        text-transform: uppercase;

        a{
            color: black;
            text-decoration: none;
        }
    }
    & + .category{
        margin-top: -25px;
    }
}
.description{
    color: #9a9a9a;
  font-weight: 300;
}
.ml-auto{
    margin-left: auto !important;
}
.mr-auto{
    margin-right: auto !important;
}
.input-lg{

}
.now-ui-icons {
    display: inline-block;
  font: normal normal normal 14px/1 "Nucleo Outline";
  font-size: inherit;
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.users_circle-08{
    content: "\ea23";
}
.input-group-focus{
    background-color: #ffffff;
  border-color: #f96332;
}
.ui-1_email-85{
    content: "\ea2a";
}
.textarea-container{
    padding: 40px 40px;
}
.send-button{
    padding: 40px 40px;
}
.btn-round{
    border-width: 1px;
  border-radius: 30px !important;
  padding: 11px 23px;
}
.btn-round[class*="btn-outline-"] {
    padding: 10px 22px;
  }